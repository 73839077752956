<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('settings.service_fees')"
        :paths="[{title:$t('settings.title'),link:'settings'}]"
        :btn="true"
        :btn-title="$t('users.vendor.new_btn')"

        @btnAction="showModal = true;modalData=null"
    />

    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderData"


      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table d-flex flex-column">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getData"
                :per-page="filterData.per_page"
                :page-number="filterData.page"
                :items="getData"
                :fields="tableFields"
                :custimized-items="[
                { name: 'status' },
                { name: 'fee' },
                { name: 'action' },
              ]"
            >


              <template slot="status" slot-scope="{ data }">
                <wameed-switch
                    :checked="data.item.status ===1"
                    @onChange="()=>onChange(data.item)"/>
              </template>

              <template slot="fee" slot-scope="{ data }">
                <check-fee
                    :fee-type="data.item.fee_type"
                    :fee="data.item.fee"
                />
              </template>

              <template slot="action" slot-scope="{ data }"
                        v-if="data.item.type==null">
                <b-dropdown
                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>

                  <b-dropdown-item
                      class="permission-edit-item"
                      v-permission="[$route.meta.pagePermissions.edit]"
                      @click="()=> openUpdateModal(data.item)">

                    <span class="text-regular-14 text-font-secondary">

                      <edit-icon class="mx-2"/>
                      {{ $t('btn.edit') }}
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                      class="permission-delete-item"
                      v-permission="[$route.meta.pagePermissions.delete]"
                      @click="deleteModal = true; modalData = data.item">
                    <span class="text-regular-14  text-danger">
                      <trash-icon class="mx-2 "/>
                      {{ $t('btn.delete') }}
                    </span>
                  </b-dropdown-item>

                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>

    <fee-modal
        :visible="showModal"
        @close="showModal = false;modalData=null"
        :modal-data="modalData"
        @onComplete="()=>loadData()"
    />


    <warning-modal
        variant="danger"
        iconName="trash-icon"
        :visible="deleteModal"
        @close="deleteModal = false"
        @submitAction="deleteAction"
        :title="$t('settings.fees.modal.delete')"
        :subTitle="$t('settings.fees.modal.delete_desc')"
        :btnTitle="$t('btn.delete')"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';

import WarningModal from "@/components/WarningModal";

import {WameedPagination, WameedSwitch, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import NameTile from "@/components/nameTile.table";
import FeeModal from "@/views/pages/settings/service_fees/components/feeModal";
import CheckFee from "@/views/pages/settings/service_fees/components/checkFee";

export default {
  components: {
    CheckFee,
    FeeModal,
    NameTile,
    WarningModal,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WameedSwitch,
    WTables,
  },
  data() {
    return {
      showModal: false,
      deleteModal: false,
      modalData: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('common.status'),
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          type: 'text',
          data: [
            {
              id: 'active',
              title: this.$i18n.t('common.exist'),
            },
            {
              id: 'not_active',
              title: this.$i18n.t('common.not_exist'),
            },
          ],
        },
        {
          title: this.$i18n.t('settings.service_fees'),
          key: 'types',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          type: 'text',
          data: [
            {
              id: '%',
              title: this.$i18n.t('discounts.discounts.%'),
            },
            {
              id: 'v',
              title: this.$i18n.t('discounts.discounts.v'),
            },
          ],
        },
      ],
      tableFields: [
        {
          key: 'index',
          label: '#',
          sortable: false,

        },
        {
          key: 'name',
          label: this.$i18n.t('category.name'),
          sortable: false,
          tdClass: 'w-2/8'
        },
        {
          key: 'category',
          label: this.$i18n.t('users.table.type'),
          sortable: false,
          tdClass: 'w-1/8'
        },

        {
          key: 'phone',
          label: this.$i18n.t('users.table.phone'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'fee',
          label: this.$i18n.t('settings.service_fees'),
          sortable: false,
          tdClass: 'w-1/8'
        },

        {
          key: 'status',
          label: this.$i18n.t('common.status'),
          sortable: false,
          tdClass: 'w-1/5'
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/5'
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/fees/getData',
      getTotal: 'admin/fees/getTotal',
    }),
  },
  async created() {


    await this.$store.dispatch('admin/fees/getApplyFor');
    this.loadData();
  },
  methods: {
    ...mapActions({
      _getData: 'admin/fees/get',
      _deleteData: 'admin/fees/delete',
    }),

    openUpdateModal(item) {
      this.showModal = true;
      this.modalData = {
        id: item.id,
        type: item.fee_type,
        value: item.fee,
        vendor: {
          id: item.vendor_id,
          name: item.name
        }
      }
    },
    onChange(item) {
      let status = 1;
      if (item.status === 1) {
        status = 0;
      }

      this.$store.dispatch('admin/fees/toggle', {id: item.id, status: status, vendor_id: this.vendor_id})
    },

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page:1,
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },

    loadData() {

      this._getData({...this.filterData, vendor_id: this.vendor_id});
    },

    deleteAction() {
      this.deleteModal = false
      this._deleteData({id: this.modalData.id, vendor_id: this.vendor_id});
    },


    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
